<template>
    <div class="row" style="padding: 15px 0.5rem;margin:0px">
        <div class="col-sm-7 left-side">
            <div class="box-top shadow row">
                <div class="col-sm-8">
                    <div class="row box-top-title col-sm-12">Selamat Datang, &nbsp;<b>{{name}}</b></div>
                    <div class="row box-top-desc col-sm-12">Sistem informasi ini dibangun agar Bimbel Taruna Cendekia semakin maju ke depan. Silahkan hubungi admin jika ada kendala atau kesulitan dalam mengoperasikan.</div>
                </div>
                <div class="col-sm-4" style="text-align-last: end;">
                    <img src="@/assets/img/Taruna-maskot-2.png" class="box-top-img" alt="none">
                </div>
            </div>

            <span style="font-size:18px">Range Tanggal</span>
            <div class="col-md-6 form-group" style="padding:0px;margin-bottom: 12px;margin-top: 6px;">
                <input type="text" class="form-control" ref="daterange">
            </div>

            <div class="shadow" style="width:100%;min-height: 262px;background:white;padding-left: 31px;padding-top: 24px;border-radius: 20px;margin-bottom: 12px;">
                
                    <span style="font-size:18px">Trafic User</span>
                    <div><small>Aktivitas Siswa dan Tentor</small></div>
                    <canvas ref="chartbatang" id="siswa-chart" height="200" style="display: block; width: 487px; height: 200px;" width="487" class="chartjs-render-monitor"></canvas>
                
            </div>
            <div class="shadow" style="width:100%;min-height: 262px;background:white;padding-left: 31px;padding-top: 24px;border-radius: 20px;">
                <span style="font-size:18px">Trafic Unit</span>
                <div><small>Aktivitas user per unit</small></div>
                <canvas ref="chartscater" id="keaktifan-chart" height="200" style="display: block; width: 487px; height: 200px;" width="487" class="chartjs-render-monitor"></canvas>
            </div>
        </div>
        <div class="col-sm-5" style="padding-left: 24px;">
            <div class="right-side">
                <div class="row" style="margin-bottom:12px">
                    <div class="col-sm-6" style="font-size:18px;align-self: center;">Log System</div>
                    <div class="col-sm-6" style="text-align: right;"><router-link to="/logwatch" class="btn" style="background-image: linear-gradient(#EC008C, #FC6767);color: white;">Lihat Semua</router-link></div>
                </div>
                <div class="col-md-12 form-group" style="padding: 0px;">
                    <input class="form-control" v-model="q" type="text" @input="loadActivity" placeholder="Cari aktifitas atau user..." autocomplete="off" />
                </div>
                <div class="row">
                    <div class="col-sm-12" v-for="log in activities">
                        <div class="callout"  :class="log.class">
                            <div class="row">
                                <div class="col-sm-8">
                                  <h5 style="font-size:14px;font-weight:600;color: #7D7D7D;">{{log.message}}</h5>
                                  <p style="font-size:14px;font-weight:400;color: #9590A0;">{{log.username}} | {{log.created_on}} | <b>{{log.action}}</b></p>
                                </div>
                                <div class="col-sm-4" style="text-align: -webkit-right;">
                                    <div class="event-circle">
                                        <IconifyIcon :icon="icons.activityFill" :style="{fontSize: '42px', color:'white'}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import {auth, authFetch} from '@/libs/hxcore';
    import IconifyIcon from '@iconify/vue';
    import activityFill from '@iconify/icons-eva/activity-fill';
    import Chart from 'chart.js';
    import Daterangepicker from "daterangepicker";
    import moment from "moment";
    import "daterangepicker/daterangepicker.css";
    var chartSiswa, chartTentor, chartKeaktifan;

    export default {
        name: 'SystemDashboard',
        components: {
            IconifyIcon
        },
        data() {
            return {
                icons: {
                    activityFill
                },
                q: '',
                  dateStart: moment().subtract(7, 'days').startOf('day').format("YYYY-MM-DD"),
                  dateEnd: moment().format("YYYY-MM-DD"),
                name : '',
                imgUrl : '',
                activities: [],
                dataTrafficUser:{
                    type: 'line',
                    data: {
                        labels: [
                        ],
                        datasets: [
                        ]
                      },
                    options: {
                        responsive: true,
                        maintainAspectRatio: true,
                        legend: {
                            position: 'top',
                            display: false
                        },
                        title: {
                            display: true,

                        }
                    }
                },
                dataTrafficUnit:{
                    type: 'bar',
                    data: {
                        labels: [
                        ],
                        datasets: [
                          
                        ]
                      },
                    options: {
                        responsive: true,
                        legend: {
                            position: 'top',
                            display: false
                        },
                        title: {
                            display: true,

                        }
                    }
                }
            };
        },
        created: function() {
            this.roles = this.$route.meta.roles;
            var info = auth.user();
            console.log('user', info)
            if(info)
            {
              this.name = info.display_name;
              this.imgUrl = info.img_url;
            }
            else{
              this.imgUrl = '/dist/img/user.jpeg';
            }
            this.onReload();
            this.loadActivity();
        },
        methods:{
            onReload() {
              var params = { dt1: this.dateStart, dt2: this.dateEnd };
              var search = new URLSearchParams(params);
              authFetch(`/dashboard/system/statistic?${search}`).then((res) => {
                res.json().then((json) => {
                  this.loading = false;
                  if (json.success) {
                    this.dataTrafficUser.data = json.trafficUser;
                    this.dataTrafficUnit.data = json.trafficUnit;

                    chartSiswa.update();
                    chartKeaktifan.update();
                  }
                });
                //loading(false);
              });
            },
            loadActivity() {
              var params = { q: this.q };
              var search = new URLSearchParams(params);
              authFetch(`/dashboard/system/log?${search}`).then((res) => {
                res.json().then((json) => {
                  this.loading = false;
                  if (json.success) {
                    this.activities = json.activities;
                  }
                });
                //loading(false);
              });
            },
        },
        mounted() {
            var ctx = document.getElementById('siswa-chart').getContext('2d')

            chartSiswa = new Chart(ctx, this.dataTrafficUser);
            let self = this;

            //daterange all
            new Daterangepicker(
              this.$refs.daterange,
              {
                startDate: moment().subtract(7, 'days').startOf('day'),
                endDate: moment(),
              },
              function (d1, d2) {
                self.dateStart = d1.format("YYYY-MM-DD");
                self.dateEnd = d2.format("YYYY-MM-DD");
                self.onReload();
              }
            );
            var ctx3 = document.getElementById('keaktifan-chart').getContext('2d')

            chartKeaktifan = new Chart(ctx3, this.dataTrafficUnit);
        }
    }
</script>

<style type="text/css" scoped>
    .box-top {
        min-height: 141px;
        background-color: white;
        border-radius: 20px;
        margin-bottom: 32px;
        padding-bottom: 12px;
        padding-top: 12px;
    }

    .box-top-title {
        padding-top: 17px;
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-size: 24px;
    }

    .box-top-desc {
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #7D7D7D;
    }

    .shadow {
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 4%) !important;
    }

    .custom-container {
        padding: 31px 31px 0px 31px;
        height: 100%;
    }

    .items {
        padding-left: 32px;
        padding-top: 25px;
        margin-left: 12px;
        margin-bottom: 21px;
        min-width: 220px;
        background-color: white;
        border-radius: 20px;
        min-height: 210px;
    }

    .items-circle {
        width: 76px;
        height: 76px;
        background: linear-gradient(90deg, #4CB8C4 0%, #3CD3AD 100%);
        border-radius: 50%;
        margin-bottom: 12px;
        text-align: center;
    }

    .items-title {
        height: 40px;
        padding-top : 4px;
        padding-bottom : 4px;
        font-weight: 700;
        font-size: 24px;
        color: #363636;
    }

    .items-desc {
        padding-right: 30px;
    }

    .items-icon {
        transform: translateY(50%);
        -ms-transform: translateY(50%);
    }

    .box-top-img {
    }

    /*right side*/
    .right-side {
        background: white;
        border-radius: 10px;
        padding: 26px 19px 0px 22px;
        height: 100%;
    }

    #myCal {
        margin-top: 25px;
        margin-bottom: 65px;
        width: 100%;
        text-align-last: center;
    }

    .event-circle {
        width:48px;
        height: 48px;
        background: #C8372D;
        border-radius: 50%;
    }

    .callout {
        background: #EBEBEB;
        border-radius: 10px;
    }

    .color-calendar {
        font-size: 0.7rem;
    }
</style>